import {brandAdd, brandDel, brandList, unitAdd, unitDel, unitList,} from "@/utils/api/product/brand.js";
import {supplierAdd, supplierDiscard, supplierQuery,} from "@/utils/api/supplier.js";
import {punterAdd, punterLIstDiscard, punterSearch} from "@/utils/api/punter.js";
/*  type 模型类型
 * brand : 品牌
 * unit : 单位
 * supplier : 供应商
 */

const apiList = {
  brand: {
    getList: brandList,
    addItem: brandAdd,
    deletItem: brandDel,
  },
  supplier: {
    getList: supplierQuery,
    addItem: supplierAdd,
    deletItem: supplierDiscard,
  },
  unit:{
    getList: unitList,
    addItem: unitAdd,
    deletItem: unitDel,
  },
  punter:{
    getList: punterSearch,
    addItem: punterAdd,
    deletItem: punterLIstDiscard,
  }
};
const titleList = {
  brand: {
    title: "产品品牌",
    unit: "品牌名称",
    placeText: "请输入品牌名称",
  },
  supplier: {
    title: "供应商",
    unit: "供应商名称",
    placeText: "",
  },
  unit: {
    title: "产品单位",
    unit: "单位名称",
    placeText: "请输入单位名称",
  },
  punter:{
    title: "客户",
    unit: "客户名称",
    placeText: "请输入客户名称",
  }
};
const paramList = {
  brand: {
    searchKey: "brandName",
    delKey: "brandId",
    addKey: "brandName",
  },
  supplier: {
    searchKey: "supplierName",
    delKey: "supplierId",
    addKey: "supplierName",
  },
  unit: {
    searchKey: "unitName",
    delKey: "unitId",
    addKey: "unitName",
  },
  punter: {
    searchKey: "punterName",
    delKey: "punterId",
    addKey: "punterName",
  },
};

const modelList = {
  brand: {
    name: "brandName",
    id: "brandId",
  },
  supplier: {
    name: "supplierName",
    id: "supplierId",
  },
  unit: {
    name: "unitName",
    id: "unitId",
  },
  punter: {
    name: "punterName",
    id: "punterId",
  },
};

function getApi(type) {
  return apiList[type];
}

function getTitle(type) {
  return titleList[type];
}

function getParam(type) {
  return paramList[type];
}

// 重新组装数据
function resetModel(val = {}, type) {
  let keyVal = modelList[type];
    return {
    name: val[keyVal.name],
    id: val[keyVal.id],
    className: "",
  };
}

export { getApi, getTitle, getParam, resetModel };
