<template>
  <div
    class="zh-selectByAbc cur_p"
    v-bind="$attrs"
    :class="[className,checkModal?'active':'']"
    @mouseenter="hoverIn"
    @mouseleave="hoverOut"
    @click.stop="handleShowCheckModal"
  >
    <template v-if="list.length > 0">
      <div class="zh-selectByAbc_tag">
        <span v-for="item in list" :key="item.id">
          {{ item.name }}
        </span>
      </div>
    </template>
    <div v-else class="placeText" >{{ placeholder }}</div>
    <div class="arraw" >
      <el-icon  v-if="!list.length"><ArrowDown /></el-icon>
      <div class="icon-o" v-if="list.length && showDel" @click.stop="delTags">
        <el-icon ><Close /></el-icon>
      </div>
    </div>
  </div>
  <el-dialog
    v-model="checkModal"
    align-center
    :title="title.title"
    width="880"
    destroy-on-close
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="abcCheck"
    append-to-body
  >
    <div class="zh_select_content">
      <div class="query">
        <div class="queryBox">
          <div class="text">{{ title.title }}</div>
          <el-input
            :placeholder="'请输入' + title.title"
            class="w360"
            v-elInput
            v-model="formData.name"
            @keydown.enter="getModelList"
          ></el-input>
          <el-button type="primary" @click="getModelList"
            ><el-icon><Search /></el-icon
          ></el-button>
        </div>
        <div class="controlBox">
          <el-button type="primary" v-if="add" @click="handleAddDia">
            <el-icon><Plus /></el-icon><span class="text">新增</span></el-button
          >
          <el-button
            type="primary"
            class="ml10 btn"
            v-if="update"
            @click="handleUpdate"
            >{{ isUpdate ? "完成" : "编辑" }}</el-button
          >
        </div>
      </div>
      <div class="selectBox">
        <div
          class="selectBox_item cur_p"
          :class="!checkedItem ? 'active' : ''"
          @click="checkAbc()"
        >
          所有{{ title.unit }}
        </div>
        <div
          v-for="item in modelList"
          :key="item"
          class="selectBox_item cur_p"
          :class="item.prefix == checkedItem ? 'active' : ''"
          @click="checkAbc(item.prefix)"
        >
          {{ item.prefix }}
        </div>
      </div>
      <div class="abcCheck_info">
        <div
          class="abcCheck_single"
          v-for="(item, index) in modelList"
          :key="index"
        >
          <div
            v-if="
              item.list.length > 0 &&
              (!checkedItem || checkedItem == item.prefix.toUpperCase())
            "
          >
            <div class="abcCheck_title">{{ item.prefix }}</div>
            <div class="abcCheck_tagList" v-if="!isUpdate">
              <div
                class="abcCheck_tags animate__animated"
                v-for="(item1, index1) in item.list"
                :key="index1"
                :class="[
                  $filters.objHasStr('name', item1.name, checkedList)
                    ? 'active'
                    : '',
                  item1.className,
                ]"
                @click="checkItem(item1)"
              >
                <span class="abcCheck_tags_text"> {{ item1.name }}</span>
              </div>
            </div>
            <div class="abcCheck_tagList" v-if="isUpdate">
              <div
                class="abcCheck_tags animate__animated"
                v-for="item1 in item.list"
                :key="item1"
                :class="[
                  $filters.objHasStr('name', item1.name, checkedList)
                    ? 'active'
                    : '',
                  item1.className,
                ]"
                @click="delItem(item1)"
              >
                <span class="abcCheck_tags_text"> {{ item1.name }}</span>
                <img src="@/assets/img/index/delete.jpg" class="del" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer >
      <el-button @click="handleCloseCheckModal" class="btn1 zh-btn"
        >关闭</el-button
      >
      <el-button class="main btn1" type="primary" @click="handleOkCheckModal" v-if='!isUpdate'>
        确认</el-button
      >
    </template>
  </el-dialog>
  <!-- 新增品牌弹窗 -->
  <el-dialog
    v-model="addDia"
    :title="title.title"
    width="500"
    align-center
    append-to-body
    class="abcCheck"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="zh-brand-add-item dp_f">
      <div class="zh-brand-item-title">
        <span class="red">*</span>{{ title.title }}：
      </div>
      <div class="zh-brand-item-input">
        <el-input
          :placeholder="title.placeText"
          maxlength="20"
          v-elInput
          v-model="addInfo.name"
        />
      </div>
    </div>
    <template #footer>
      <el-button class="zh-btn btn1" @click="closeAddDia">取消</el-button>
      <el-button
        class="main btn1"
        type="primary"
        @click="handleOkAdd"
        :disabled="isOk"
        >添加</el-button
      >
    </template>
  </el-dialog>
</template>
<script setup>
import {getApi, getParam, getTitle, resetModel} from "./util.js";
import {ElMessageBox} from "element-plus";
import {
  computed,
  defineEmits,
  defineProps,
  getCurrentInstance,
  nextTick,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";

const { proxy } = getCurrentInstance();
//接收的参数
const props = defineProps({
  typeName: {
    type: String,
    default: "",
  },
  add: {
    type: Boolean,
    default: false,
  },
  update: {
    type: Boolean,
    default: false,
  },
  maxNum: {
    type: Number,
    default: 0,
  },
  list: {
    type: Array,
    default: [],
  },
  className: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  saleBack: {
    type: Boolean,
    default: false,
  },
  buyBack: {
    type: Boolean,
    default: false,
  },
});
//模型模块
const { typeName, maxNum, list, className, placeholder, saleBack ,buyBack} =
  toRefs(props);
const emit = defineEmits(["update:list", "change"]);
//模型列表
const modelList = ref([]);
//查询搜索列表
const formData = reactive({
  name: "",
});
//接口
const api = getApi(typeName.value);
//展示标题以及展示单位
const title = reactive(getTitle(typeName.value));
//已选中首字母
const checkedItem = ref("");
//已选中的模型列表
const checkedList = ref([]);
//展示已选中的模型列表
//是否编辑
const isUpdate = ref(false);
//选择模型弹窗是否展示
const checkModal = ref(false);
//弹窗是否展示
const addDia = ref(false);
//新增弹窗模型
const addInfo = reactive({
  name: "",
});
//新增规则

const showDel = ref(false)
const hoverIn = ()=>{
  showDel.value = true
}
const hoverOut = ()=>{
  showDel.value = false
}

let isFirst = true;
const isOk = computed(() => {
  if (!addInfo.name) {
    return true;
  } else {
    return false;
  }
});
/*
 * 功能模块
 */
//通过传入的list 重制模型
function resetList() {
  if (list.value?.length && isFirst) {
    list.value.forEach((element) => {
      list.value.push = [resetModel(element, typeName.value)];
    });
    isFirst = false;
  }
}
onMounted(() => {
  nextTick(() => {
    resetList();
  });
});
//打开弹窗
const handleShowCheckModal = () => {
  getModelList();
  //模型弹窗打开
  if (list.value.length > 0) {
    list.value.forEach((element) => {
      checkedList.value.push(element);
    });
  }
  checkModal.value = true;
};

//获取模型列表
const getModelList = () => {
  let key = getParam(typeName.value).searchKey;
  api
    .getList({
      [key]: formData.name || "",
      saleBack: saleBack.value,
      buyBack: buyBack.value,
    })
    .then((res) => {
      if (res.code != 0) {
        proxy.$message.error(res.msg);
        return;
      }
      let list = res.data.list || [];
      if (list.length > 0) {
        list.forEach((element) => {
          element.list.forEach((item) => {
            item.name = resetModel(item, typeName.value).name;
            item.id = resetModel(item, typeName.value).id;
          });
        });
      }
      //赋值模型列表
      modelList.value = list;
    })
    .catch((err) => {});
};

//选择首字母
const checkAbc = (item) => {
  if (!item) {
    checkedItem.value = "";
    return;
  }
  if (item == checkedItem.value) {
    checkedItem.value = "";
    return;
  }
  checkedItem.value = item;
};

//选择模型
const checkItem = (item) => {
  let index = checkedList.value.findIndex((e) => e.id == item.id);
  if (index != -1) {
    //反选品牌
    checkedList.value.splice(index, 1);
    return;
  }
  if (maxNum.value && checkedList.value.length >= maxNum.value) {
    //已选数量大于最大数量
    checkedList.value.splice(0, 1);
    checkedList.value.push(item);
    return;
  }
  checkedList.value.push(item);
};
//外层组件删除模型
const delTags = () => {
  list.value.splice(0, 1);
};

//开启编辑功能
const handleUpdate = () => {
  isUpdate.value = !isUpdate.value;
};
//删除模型
const delItem = (e) => {
  ElMessageBox.confirm("是否确认删除", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    customClass: "zh-message-box",
  }).then(() => {
    let key = getParam(typeName.value).delKey;
    api
      .deletItem({
        [key]: e.id,
      })
      .then((res) => {
        if (res.code != 0) {
          proxy.$message.error(res.msg);
          return;
        }
        proxy.$message.success("移除成功");
        checkDelItem(e);
        modelList.value.forEach((item) => {
          for (let i = 0; i < item.list.length; i++) {
            if (item.list[i].name == e.name) {
              //添加动画效果
              item.list[i].className = "animate__zoomOut";
              setTimeout(() => {
                item.list.splice(i, 1);
              }, 500);
              return;
            }
          }
        });
      });
  });
};
//判断删除模型时 模型是否在选中列表中
function checkDelItem(item) {
  let index = checkedList.value.findIndex((e) => e.id == item.id);
  if (index != -1) {
    checkedList.value.splice(index, 1);
  }
  let index2 = list.value.findIndex((e) => e.id == item.id);
  if (index2 != -1) {
    list.value.splice(index2, 1);
  }
}
//取消选择模型弹窗
const handleCloseCheckModal = () => {
  checkModal.value = false;
};

//确认模型弹窗
const handleOkCheckModal = () => {
  list.value = checkedList.value;
  emit("update:list", checkedList.value);
  checkModal.value = false;
};

//打开添加模型弹窗
const handleAddDia = () => {
  //清空添加数据
  addInfo.name = "";
  addDia.value = true;
};

//关闭添加模型弹窗
const closeAddDia = () => {
  addDia.value = false;
};

//确认添加
const handleOkAdd = async () => {
  let key = getParam(typeName.value).addKey;
  let res = await api.addItem({
    [key]: addInfo.name,
  });
  if (res.code != 0) {
    proxy.$message.error(res.msg);
    return;
  }
  proxy.$message.success("添加成功");
  await getModelList();
  addDia.value = false;
  modelList.value.forEach((item) => {
    for (let i = 0; i < item.list.length; i++) {
      if (item.list[i].name == addInfo.name) {
        //添加动画效果
        item.list[i].className = "animate__zoomIn";
        return;
      }
    }
  });
};

//监听模型弹窗关闭
watch(
  () => checkModal.value,
  (newValue, oldValue) => {
    if (!newValue) {
      checkedItem.value = "";
      checkedList.value = [];
      isUpdate.value = false;
    }
  }
);
watch(
  () => list.value,
  (newValue, oldValue) => {
    emit("change", {});
  },
  {
    deep: true,
  }
);
const isOkChoose = computed(() => {
  return checkedList.value.length > 0;
});
</script>
<script>
import { defineComponent } from "vue";
import { ArrowDown, Search, Plus,Close } from "@element-plus/icons-vue";
export default defineComponent({
  name: "selectByAbc",
  components: {
    ArrowDown,
    Search,
    Plus,
    Close
  },
  setup(props, { emit }) {
    return {};
  },
});
</script>
<style lang="scss">
@import "./index.scss";
:deep(.el-dialog__body) {
  padding: 16px;
}
.abcCheck {
  border-radius: 16px;
  .el-dialog__header {
    font-size: $fsColor;
    color: #161829;
    padding: 20px 32px;
    background-color: #f2f4f7;
    margin-right: 0;
    border-radius: 16px 16px 0 0;
  }
  .el-dialog__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    .el-button {
      padding: 8px 66px;
    }
  }
  .zh-brand-add-item {
    display: flex;
    align-items: center;
    justify-content: center;
    .zh-brand-item-title {
      width: 80px;
    }
    .zh-brand-item-input {
      width: 330px;
    }
  }
  .queryList {
    display: flex;
    align-items: center;
    .item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .query_name {
        width: 90px;
      }
      .searchBtn {
        width: 36px;
      }
    }
  }
  .tagsList {
    transition: 0.5s all linear;
    height: 112px;
    border-radius: 8px;
    background-color: #f9fbfb;
    @include overFlow();
    margin-top: 16px;
    .text {
      text-align: center;
      line-height: 112px;
    }
    .tag1 {
      margin: 16px 0 0 16px !important;
      background-color: rgba(56, 89, 255, 0.1);
    }
  }
  .search_table {
    margin-top: 20px;
  }
}
</style>
